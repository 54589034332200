<template>
  <el-dialog
    id="updatePasswordDialog"
    title="修改密码"
    :visible.sync="show"
    @closed="reset"
  >
    <el-form :model="form" :rules="formRules" ref="form" label-width="100px">
      <el-form-item label="当前密码" prop="oldPassword">
        <el-input
          type="password"
          v-model="form.oldPassword"
          placeholder="请输入当前密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input
          type="password"
          v-model="form.newPassword"
          placeholder="请输入新密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input
          type="password"
          v-model="form.confirmPassword"
          placeholder="请确认密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="medium" @click="show = false">取 消</el-button>
      <el-button
        type="primary"
        size="medium"
        @click="confirmBtn"
        :loading="loading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      formRules: {
        oldPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (this.form.newPassword !== value) {
                callback(new Error("与新密码不一致"));
              } else {
                callback();
              }
            },
            message: "与新密码不一致",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.$set(this, "form", {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      this.$refs["form"].resetFields();
    },
    async confirmBtn() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let res = await this.$http.post("/web/user/edit/password", {
              newPassword: this.form.newPassword,
              oldPassword: this.form.oldPassword,
            });
            if (res.code === 0) {
              this.$message.success("操作成功");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
#updatePasswordDialog {
  &.el-dialog__wrapper {
    .el-dialog {
      width: 400px;

      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);

        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }

        .el-dialog__headerbtn {
          position: initial;

          i {
            color: black;
          }
        }
      }

      .el-dialog__body {
        padding: 15px;

        .el-select {
          width: 100%;
        }
      }

      .el-dialog__footer {
        text-align: center;

        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
